@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');

/**************************[ BASE ]**************************/
body {
	font-family: 'Open Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
	color: #000;
	background-color: #fff;
}

a, body, i, p, span, u {
	font-size: 16px;
	line-height: 24px;
}
h1 {
	font-size:40px;
	letter-spacing:1px;
	text-transform:uppercase;
	font-family: 'OpenSansCondensed-Bold', sans-serif;
	font-weight:bold;
	color:#000;
}
h2 {
	font-size:36px;
	font-weight:400;
	letter-spacing:0;
	text-transform: none !important;
	line-height:36px;
	font-family: 'OpenSansCondensed-Light', sans-serif;
}
h3 {
	font-size:20px;
	font-weight:400;
	line-height:inherit;
	font-family: 'Open Sans', sans-serif;
}
h4 {
	font-weight:bold;
	font-size:16px;
	font-family: 'Open Sans', sans-serif;
}
h6 {
	font-size:24px;
	letter-spacing:3px;
	text-transform:uppercase;
	font-family: 'Open Sans', sans-serif;
}
.wrapper {
	border-top:none;
}

#opc-login h3, #subscribe-form label, .accordion .heading, .block .block-title, .box-account .box-head h2, .caption .heading, .feature .heading, .footer .heading, .gen-tabs .tabs a, .main-font, .mobnav-trigger, .nav-mobile li.level0>a, .nav-mobile li.level1>a, .nav-mobile li.level2>a, .nav-regular .nav-submenu--mega>li>a, .nav-regular li.level0>a, .order-items .order-comments h2, .order-items h2.sub-title, .order-items h2.table-caption, .product-view .box-reviews dt .heading, .products-grid .product-name, .products-list .product-name, .section-title, body, h5
{
	font-family: 'Open Sans', sans-serif !important;
}

.gen-tabs .tabs a.current {
	color:#000;
}

/**************************[ HEADER ]**************************/
.header a, .header p, .header span {
	font-size: 14px;
	color: #fff;
	font-family: 'Open Sans', sans-serif;
}
.header .dropdown, .header-top {
	line-height: normal;
}

/* Top bar */
.header-container {
	background-color: #fff;
	padding-bottom: 0;
}
.header-top-container {
	background-color:#333;
	box-shadow:0 0 0 1px #e5e5e5;
}

.header-top-container .header-top {
	line-height: normal;
}
.welcome-msg *, .userCode, .userName {
	color: #fff !important;
}

/* Logo */
.logo img {
	max-width: 250px;
}

/* Search bar */
.search-wrapper-centered .form-search {
	position: relative;
}
.search-wrapper-centered .form-search .input-text {
	width: 100%;
	background-color: #fff;
	border-color: #999;
	color: #777;
	font-family: 'Open Sans', sans-serif;
	display: block;
}
.search-wrapper-centered .form-search .button-search {
	position: absolute;
	top: 0;
	right: 0;
}
.search-wrapper-centered .form-search .button-search .fa {
	font-size: 16px;
	color: #777;
	padding: 10px;
}
.search-wrapper-centered .form-search-globalfilter {
	position: relative;
}
.search-wrapper-centered .form-search-globalfilter label[for="ignore-filter-ignore"] {
	font-size: 12px;
}

/* Cart Widget */
.user-menu, .user-menu a {
	color: #fff;
}
.dropdown-toggle *, .header span.cart-total, .header span {
	color: #000;
}
.dropdown-toggle .value {
	color: #fff;
}
.dropdown.open .dropdown-toggle .value {
	color: #000;
}
.header .open > .dropdown-toggle.cover > div {
	background-color: transparent;
}
.feature-icon-hover .caret {
	padding-top: 10px;
	font-weight: 700;
	border-top-color: #000;
	border-width: 6px 5px 0; opacity:1;
}
.dropdown-toggle .icon.close-to-text {
	display: none;
}
.dropdown-toggle .hide-below-1280,
.dropdown-toggle .amount {
	padding: 0.5em 5px;
	font-size: 14px;
}
.empty span.price {
	margin-top: 5px;
	padding-right: 0.5em;
}
.header .fa.fa-shopping-cart {
	position: relative;
	top: 8px;
	left: -15px;
	font-size: 18px;
}
#root-wrapper span.fa {
	font-size: 18px;
}

/* Menu */
.custom-freeshipping-note a, .links > li > a, .nav-regular li.level0 > a > span {
	color: #000;
	font-size: 24px;
	font-family: 'OpenSansCondensed-Bold', sans-serif;
}

.links > li > a:hover {
	background-color: #000;;
	color: #ffffff !important;
}
#nav {
	margin-top: 0;
	margin-bottom: 0;
}
#nav, .mobnav-trigger {
	background-color: #fff;
	border-bottom: none;
}
.nav-regular .nav-item.level0:hover > a,
.nav-regular .nav-item.level0:hover > a > span,
.nav-mobile .nav-item.level0 > a:hover {
	background-color: transparent;
	color: #000;
}
.nav-regular li.level0 > .nav-panel--dropdown, .nav-regular .mega > .nav-panel--dropdown, .nav-regular .classic > .nav-panel--dropdown {
	border-top: 5px solid #000;
}
#nav .nav-panel--dropdown a {
	color: #777;
	font-size: 14px;
}
#nav .nav-panel--dropdown a > span {
	font-size: 14px;
}
#nav .nav-panel--dropdown a:hover {
	color: #000;
}
.mobnav-trigger.active {
	background-color: #000;
}
.cms-index-index .nav-regular .nav-item--home > a, .nav-regular .nav-item.level0.active > a, .nav-mobile .nav-item.level0.current > a {
	background-color: transparent;
	color: #000;
}
#nav .nav-item--home .fa-home {
	color: #000 !important;
	padding: 0.3rem;
}
#root-wrapper a:hover span.fa:before {
	color: #000;
}
#nav .nav-item.level0.parent:hover > a .caret {
	border-top-color: #000;
}
#nav .nav-panel--dropdown a:hover .caret {
	border-left-color: #000;
	border-right-color: #000;
}
#mini-cart .actions button {
	width: 100%;
}
#mini-cart .actions button.button span {
	background-color: transparent !important;
	width: 100%;
}
#mini-cart .actions button.button span span {
	background-color: #fff !important;
	padding: 0;
	border-radius: 10px;
	max-height: 45px;
	font-size: 16px;
	margin-top: 10px; color:#000;
    border: 1px solid #000;
}
#mini-cart .actions button.button span span:hover {
	background-color: #000 !important; 
    color:#fff;
}
.dropdown-menu > li * {
	float: none;
	display: inline-block;
}
.dropdown-menu > li > a {
	display: inline-block;
}

/**************************[ BODY ]**************************/
.breadcrumbs a, .breadcrumbs li {
	font-size: 14px;
}
.breadcrumbs li span {
	margin: 10px 4px 0;
	font-size: 11px;
	letter-spacing: 2px;
	text-transform: uppercase;
}
.breadcrumbs li span.breadcrumb-separator {
	background: url(../images/img/pix.png) 0 -129px no-repeat;
	width: 7px;
	height: 5px;
	display: block;
	float: left;
	text-indent: -9999px;
	overflow: hidden;
	margin: 12px 4px 0;
}
h1, h1.banner-h1, .banner-h1 a {
	font-size: 40px;
	color: #000;
	letter-spacing: 1px;
	text-transform: uppercase;
}
.pager .amount, .sorter .amount {
	line-height: 36px;
}
.toolbar label, .toolbar p, .toolbar span {
	font-size: 14px;
}
.products-grid.single-line-name .product-name {
	overflow: visible;
	text-overflow: initial;
	word-wrap: break-word;
	white-space: normal;
}
.products-grid.centered .item {
	font-size: 14px;
	line-height: 20px;
}
.products-grid .product-name, h3.product-name a {
	font-size: 16px;
}
.bootstrap-styles h2 {
	letter-spacing: 0;
}
.product-view .btn-cart span, button.button span {
	color: #fff;
}
.add-cart-wrapper button span span {
	width: 140px;
	max-width: 140px;
}
.product-view .btn-cart span, button.button span {
	margin: 0;
	background-color: #000;
	border-radius: 10px;
	padding: 0;
}
button, .button, .button a, button a {
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
}
button.button span {
	font-size: 16px;
}
button.button span span {
	padding: 0 10px;
}
.add-cart-wrapper button:last-child {
	margin-top: 10px;
}
.btn-filled:hover, .button:hover span {
	background-color: #FF0000 !important;
}
.products-grid.centered .item {
	line-height: 20px;
}
.item .price-box .price, .item .price-box .price span, .xm-owl-carousel .price-box .price, .xm-owl-carousel .price-box .price span {
	font-size: 14px;
	color: #000;
}
.price-box .minimal-price .price, .price-box .regular-price .price, .price-box-bundle .full-product-price .price {
	color: #000;
}
.main .col-main, .main .sidebar {
	margin-top: 1%;
	margin-bottom: 1%;
}
.account-login {
	margin: 15px 0 0 0;
	background-color: #fff;
}
.block-title span {
	font-size: 20px;
}
.form-list label {
	font-weight: unset;
}
div[class^='account'] p, div[class^='account'] h2 {
	text-align: left;
}

div[class^='account'] .form-list input.input-text {
	float: left;
}
.category-title, .my-account .page-title {
	border-bottom: none;
}
.pager .pages li a {
	background-color: #f5f5f5;
}
.pager .pages li.current,
.pager .pages li a {
	width: 30px;
	height: 30px;
	line-height: 30px;
}

/**************************[ Footer ]**************************/
.footer-container {
	background-color: #ddd;
}
.footer-container, .footer-container2, .footer-primary.footer.container, .footer-top
{
	background-color: #ebebeb;
}
.footer-top-container {
	padding-top: 10px;
}
.footer-primary-container {
	background-color: transparent;
	padding-top: 10px;
	padding-bottom: 10px;
}
.footer-bottom-container.section-container {
	background-color: #333;
	color: #999;
}


/************************** MICS **************************/

/*Header:starts*/
.header-top-container {
     background-color:#000;
     box-shadow:none;
}
.xm-grid-header .top-header-container .nested-container, .desktop-top-header .nested-container {
     margin:0 auto;
}
.desktop-top-header .nested-container .grid-container {
    border-bottom: 1px solid #DADADA;
    padding-bottom: 20px;
}
.header-top-container .module-header-multi, .header-top-container .module-header-multi .item-welcome-msg {
     margin-left:0;
}
.header-top-container .module-header-multi {
     margin-right:0;
}
/*Align the text on the top header*/
.top-header-container .module-header-multi span, .top-header-container .module-header-multi a {
     line-height:2em;
    font-size:16px;
}
.top-header-container .module-header-multi p {
     margin-bottom:0;
     line-height:2em;
}
.top-header-container .item.item-user-links {
     margin-right:0;
}
.top-header-container .links > li > a {
     color:#fff;
     font-size:16px;
     font-weight:400;
     font-family:"Open Sans";
     line-height:2em;
}
/*Logo*/
@media only screen and (min-width:960px) {
     .desktop-top-header .logo-column img {
         width:70%;
    }
}
.desktop-top-header .logo-column {
     margin-left:0 !important;
}
/*Menu*/
.desktop-top-header .module-category-menu .nav-item.nav-item--home {
     display:none;
}
.desktop-top-header .grid-container, .desktop-top-header ul#nav {
     display:flex;
}
.desktop-top-header .grid-container > div {
     margin:auto;
}
.desktop-top-header ul#nav > li.level0 {
     margin:0 auto;
}
.desktop-top-header .nav-regular li.level0>a {
     text-transform:inherit;
     font-weight:600;
     font-size:24px;
}
/*search*/
.desktop-top-header .module-search .form-search .button-search .fa, .desktop-top-header .module-search .form-search input::placeholder {
     color:#000 !important;
     font-weight:600;
}
.desktop-top-header .module-search .form-search .input-text {
     border:1px solid #000000;
     border-radius:10px;
     font-weight:700;
     font-size:20px;
}
/*cart*/
.desktop-top-header .cart-column .fa-shopping-cart {
     font-size:22px;
}
.feature-icon-hover > a {
     text-align:center;
}
/*Change the text 'Switch Customer' to a FA icon for small width devices.*/
@media only screen and (max-width:1199px) {
     .userSwitch a {
         text-indent:-9999px;
         position:absolute;
    }
     .userSwitch a::after {
         content:"\f2c3";
    }
     .userSwitch a::after {
         font-family:fontAwesome;
         font-size:14px;
         text-indent:0;
         float:left;
    }
}
/*Hide-below-960/hide-above-960 doesn't work in all cases, so add the code but not for the mini cart*/
@media only screen and (max-width:959px) {
     .hide-below-960 {
         display:none !important;
    }
     #mini-cart .hide-below-960 {
         display:inline-block !important;
    }
}
@media only screen and (min-width:960px) {
     .hide-above-960 {
         display:none !important;
    }
}
@media only screen and (min-width:768px) {
     .hide-above-768 {
         display:none !important;
    }
}
@media only screen and (max-width:767px) {
     .hide-below-768 {
         display:none !important;
    }
}
/*Change the account menu icon for mobile/tablet*/
.fa-vcard:before{
     content:"" !important;
}
.fa-vcard:after{
     content:"\f007";
     color:#fff;
}
/*Sticky header*/
#header {
     position:sticky;
     top:0;
     z-index:50;
}
/*Set all fa icons to be normal style*/
.std i {
     font-style:normal;
}
/*Header for tablet*/
@media only screen and (min-width:768px) and (max-width:959px) {
     .desktop-top-header .category-column {
         width:2%;
    }
     .desktop-top-header .search-column {
         width:23%;
    }
     .desktop-top-header .cart-column {
         width:18%;
    }
     .desktop-top-header {
         overflow:unset;
    }
     .fa.fa-bars.userCode {
         vertical-align:inherit;
    }
     .desktop-top-header, .desktop-top-header .search-column {
         overflow:unset;
    }
}
/*Inline search results*/
 #inline-search-results {
     right:0;
     left:initial;
}
@media only screen and (min-width:960px) {
     #inline-search-results {
         width:300%;
         right:0;
         left:initial;
    }
     #inline-search-results .grid12-8.results-right {
         width:68%;
    }
     #inline-search-results li.grow {
         width:45%;
    }
}
@media only screen and (max-width:768px) {
     #inline-search-results {
         width:96%;
         left:0 !important;
         margin:auto;
    }
}
/*Header for Mobile*/
@media only screen and (max-width:767px){
     .mobile-header .fa, .mobile-header .fa-vcard:after{
         color:#000 !important;
         font-size:22px !important;
    }
     .mobile-header{
         margin-bottom:0;
    }
     .mobile-header .header-top-container {
         background-color:#C0C0C0;
    }
     .mobile-header .grid-container .grid-full{
         display:flex;
    }
     .mobile-header .grid-full > div {
         margin:auto;
    }
     .mobile-header .logo img {
         width:70%;
    }
     .mobile-header .form-search .button-search .fa {
         color:#000 !important;
    }
     .mobile-header .fa.fa-shopping-cart {
         top:2px;
         left:0;
    }
     .mobile-header .feature-icon-hover .empty {
         display:none;
    }
     /*Style the search icon for the theme*/
     .mobile-header .module-search .form-search .input-text {
         display:none;
    }
     .mobile-header .module-search .button.button-search {
         position:unset!important;
    }
     .mobile-header .item-search.fly-out {
         position:fixed;
         display:block!important;
         top:0;
         right:0;
         left:0;
         bottom:0;
         z-index:9999;
         width:100%!important;
         background-color:#fff;
         margin-left:0;
    }
     .mobile-header .item-search.fly-out .form-search .button {
         right:10px;
         position:absolute!important;
         margin:0;
         padding:0;
         font-size:inherit;
    }
     .mobile-header .item-search.fly-out .form-search .button-search {
         right:45px;
    }
     .mobile-header .form-search .button-close .fa {
         font-size:16px;
         color:#000;
         padding:10px;
         font-style:normal;
    }
}
/*Small desktops*/
@media only screen and (min-width:960px) and (max-width:1199px) {
     .nav-regular li.level0>a>span {
         font-size:18px;
    }
}
/*Header:ends*/
/**Footer:starts**/
.footer-container, .footer-container2, .footer-primary.footer.container, .footer-top{
     background-color:#DADADA;
}
.footer-bottom-container.section-container {
     background-color:#DADADA;
}
.footer-bottom-container.section-container .grid-full {
     width:100%;
     margin:0;
}
#footer img {
     width:25%;
}
#footer a {
     color:#000 !important;
}
.footer-bottom-container.section-container .item-left {
     float:right;
     color:#000 !important;
     margin-right:0 !important;
}
.footer-links-column a {
     font-size:20px !important;
}
.footer-bottom-container.section-container .item-left p {
     font-size:18px;
}
@media only screen and (min-width:480px) {
     .footer-top-container .section {
         width:100%;
         display:flex;
    }
     .footer-top-container .section > div {
         margin:auto;
    }
     .footer-links-column {
         text-align:right;
    }
}
@media only screen and (max-width:479px) {
     .footer-links-column {
         margin-top:20px;
    }
     .footer-links-column .item-default {
         display:flex;
    }
     .footer-links-column .item-default > p {
         margin:auto;
         margin-bottom:0 !important;
    }
     .footer-bottom-container.section-container .item-left {
         float:none;
    }
     .footer-links-column a {
         font-size:16px !important;
    }
     .footer-bottom-container.section-container .item-left p {
         font-size:14px;
    }
}
@media only screen and (min-width:960px) {
     .footer-top-container {
         padding-top:40px;
    }
}
/**Footer:ends**/

/*Category pages*/
/*Breadcrumbs*/
#breadcrumbs li span{
     font-size:14px !important;
     color:#000 !important;
}
.product-search-container {
     margin-left:0;
}
.product-search-container .sidebar-category .block-title span, .product-search-container .category-title h1{
     padding:0 !important;
}
/*Pagination*/
.pager {
     border:none;
     margin:0;
     padding:0;
}
.pagination .module-category-misc {
     text-align:right;
}
.pager li a, .pager strong{
     font-size:12px;
}
.pager .pages li a{
     background-color:#DADADA;
     color:#000;
}
.pager .pages li.current {
     background-color:#fff;
     color:#000;
}
.pager .pages li a:hover {
     background-color:#DADADA;
}
/*Sidebar*/
.product-search-container .block-title span {
     font-size:24px;
     font-weight:300;
}
.product-search-container .block-content .accordion-style1 li a {
     font-size:16px !important;
     border-bottom:none;
}
/*Listing*/
.category-products-grid .item {
     border:none;
     margin-bottom:100px;
}
 .item .module-category-product-listing span, .module-category-product-listing li, .module-category-product-listing li strong, .item .module-category-product-listing .product-name a {
     font-size:20px !important;
}
.item .module-category-product-listing h2.product-name {
     margin-bottom:15px;
}
.item .module-category-product-listing h2.product-name a {
     color:#000;
}
.item .module-category-product-listing .desc.std {
     margin-top:15px;
     margin-bottom:15px;
     font-size:18px;
}
.item .module-category-product-listing .product-image-wrapper.product-description-wrapper {
     margin-bottom:30px;
}
.item .module-category-product-listing .product-description-wrapper .product-shop-inner {
     min-height:60px;
}
.item .module-category-product-listing .price-box {
     margin-top:15px;
}
@media only screen and (max-width:959px) {
     .item .module-category-product-listing .grid12-12 {
         overflow-y:hidden;
    }
     .item .module-category-product-listing .item-code-label {
         display:none;
    }
}
@media only screen and (max-width:767px) {
     .products-grid .item button.btn-cart span span {
         padding:0 12px !important;
    }
}
/*Hover effect*/
.category-products-grid .item:hover {
     z-index:10;
     box-shadow:0 0 20px rgba(0,0,0,.2);
     border-color:transparent;
     transition:box-shadow .4s ease-out;
     -moz-transition:box-shadow .4s ease-out;
     -webkit-transition:box-shadow .4s ease-out;
     -o-transition:box-shadow .4s ease-out;
}
/*Buttons*/
.item .module-category-product-listing button.button span {
     color:#000;
     background-color:#fff;
}
.item .module-category-product-listing button.button span span{
     border:1px solid #000;
}
.item .module-category-product-listing button.button:hover span {
     color:#fff;
     background-color:#000;
}
.item .module-category-product-listing button.button:hover span span{
     border:1px solid #ff0000;
}
/*Expand the width for small width devices*/
@media only screen and (max-width:959px) {
     .product-listing {
         width:98% !important;
    }
}
.quickviewbutton {
     background-color:#fff;
     border-radius:10px;
}
@media only screen and (max-width:767px) {
     .quickviewbutton {
         display:none;
    }
}
#quick-view-modal .modal-footer {
     border-top:none;
}
#quick-view-modal .item-code-label, #quick-view-modal #unit-messure-wrapper {
     display:none;
}
#quick-view-modal button.button span span {
     padding:0 24px;
}
/*Category pages:end*/
